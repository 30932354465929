import React, { useState, useEffect } from "react";
import Modal from "./Modal";
import fantI from "../imgs/fant_1.png";
import fantII from "../imgs/fant_2.png";
import fantIII from "../imgs/fant_3.png";

const OpiumBrowser = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    description: "",
    imageUrl: "",
  });

  useEffect(() => {
    // Handle right-click prevention
    const handleRightClick = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleRightClick);
    document.title = "4m0r - Fantomo HDTPM";
    return () => {
      document.removeEventListener("contextmenu", handleRightClick);
    };
  }, []);

  const openModal = (title, description, imageUrl) => {
    setModalContent({ title, description, imageUrl });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openDownloadModal = () => {
    setIsDownloadModalOpen(true);
  };

  const closeDownloadModal = () => {
    setIsDownloadModalOpen(false);
  };

  const downloadFile = (fileUrl) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileUrl.split("/").pop();
    link.click();
  };

  return (
    <div className="min-h-screen bg-black p-8">
      {/* Header Section */}
      <header className="text-center mb-12">
        <h1 className="text-4xl font-bold text-red-600">Fantomo Browser</h1>
        <p className="text-xl text-gray-700 mt-4">
          A fast and secure browser for seamless browsing experience
        </p>
      </header>

      {/* Features Section */}
      <section className="mb-16">
        <h2 className="text-3xl font-semibold text-white text-center mb-8">
          Features
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div
            onClick={() =>
              openModal(
                "Fast Browsing",
                "Opiom Browser doesn’t mess around. We ripped out all the bloated crap that other browsers shove in, so you get nothing but raw, unfiltered speed. No BS, no slowdowns—just pages loading faster than you can say, “What the hell just happened?” Quit wasting time with sluggish browsers. With Opiom, it’s fast as f***, all day, every day. Try it now and feel the difference, you’ll thank us later.",
                fantI
              )
            }
            className="bg-gray-500 p-6 rounded-lg shadow-lg cursor-pointer hover:shadow-2xl"
          >
            <h3 className="text-xl font-semibold text-white">Fast</h3>
            <p className="text-gray-300 mt-4">
              Fantomo Browser is all about getting you where you need to
              go—fast.
            </p>
          </div>
          <div
            onClick={() =>
              openModal(
                "Security",
                "Fantomo Browser doesn’t save your passwords, cookies, or any of your private crap. Why? Because it’s none of our damn business. We don’t track you, spy on you, or sell your data like thoseother shady-ass browsers. What you do online stays yours—period. So go ahead, browse whatever the f*** you want. With Fantomo, you’re untouchable.",
                fantII
              )
            }
            className="bg-gray-500 p-6 rounded-lg shadow-lg cursor-pointer hover:shadow-2xl"
          >
            <h3 className="text-xl font-semibold text-white">Security</h3>
            <p className="text-gray-300 mt-4">
              We don’t give a sh*t about your data (And Neither Will Anyone
              Else).
            </p>
          </div>
          <div
            onClick={() =>
              openModal(
                "User-Friendly",
                "Opiom Browser keeps it stupid simple. One button, one input—that’s it. No clutter, no menus, no “features” you’ll never use. Just type, click, and boom—you’re browsing. It’s so easy, even a goldfish could figure it out. Hell, you might even feel a little insulted by how dumb-proof it is. But hey, that’s the point. Opiom does the job, no hand-holding required.",
                fantIII
              )
            }
            className="bg-gray-500 p-6 rounded-lg shadow-lg cursor-pointer hover:shadow-2xl"
          >
            <h3 className="text-xl font-semibold text-white">User-Friendly</h3>
            <p className="text-gray-300 mt-4">Even your Grandma Could Use It</p>
          </div>
        </div>
      </section>

      {/* Download Section */}
      <section className="text-center mb-16">
        <h2 className="text-3xl font-semibold text-gray-100 mb-8">Download </h2>
        <div
          onClick={openDownloadModal}
          className="bg-red-500 text-white py-2 px-6 rounded-lg cursor-pointer hover:bg-green-600 transition duration-200 max-w-xs mx-auto"
        >
          <h3 className="text-xl font-semibold">Download Software</h3>
          <p className="mt-2 text-center">
            Click here to download the latest version of Fantomo Browser
          </p>
        </div>
      </section>

      {/* Modals */}
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={modalContent.title}
        description={modalContent.description}
        imageUrl={modalContent.imageUrl}
      />

      {/* Download Modal */}
      {isDownloadModalOpen && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2 max-h-[80vh] overflow-y-auto relative">
            <button
              onClick={closeDownloadModal}
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Download Fantomo Browser
            </h2>
            <p className="text-gray-600 mb-4">
              By using this software, you acknowledge and agree to the following
              terms:
            </p>

            <ul className="text-gray-600 mb-4 list-inside list-disc">
              <li>
                <strong>No Liability:</strong> The use of this software is at
                your own risk. [L0veC0mp4ny] is not responsible for any direct,
                indirect, incidental, special, consequential, or punitive
                damages arising from the use or inability to use this software,
                even if [L0veC0mp4ny] has been advised of the possibility of
                such damages.
              </li>

              <li>
                <strong>Usage Responsibility:</strong> You are solely
                responsible for how you use this software. [L0veC0mp4ny] does
                not guarantee or assume any liability for the accuracy,
                reliability, or legality of the data processed, transmitted, or
                accessed through this software.
              </li>

              <li>
                <strong>Intellectual Property:</strong> All rights to the
                software, including its design, code, and content, are owned by
                [L0veC0mp4ny]. Unauthorized copying, redistribution, or use of
                any part of this software is prohibited. All rights are
                reserved.
              </li>

              <li>
                <strong>Modifications:</strong> [L0veC0mp4ny] reserves the right
                to modify or discontinue the software or any part of it without
                notice.
              </li>

              <li>
                <strong>No Warranties:</strong> This software is provided "as
                is" without warranties of any kind, either express or implied,
                including but not limited to the implied warranties of
                merchantability or fitness for a particular purpose.
              </li>
            </ul>

            <p className="text-gray-600 mb-4">
              Click the button below to start downloading the software:
            </p>

            <button
              onClick={() =>
                downloadFile("https://example.com/path/to/Fantomo-browser.zip")
              }
              className="bg-blue-500 text-white p-4 rounded-lg hover:bg-blue-600 transition duration-200"
            >
              Download Now
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default OpiumBrowser;
