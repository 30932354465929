import React, {useState, useEffect} from 'react';
import axios from 'axios';
import QRCode from 'qrcode';
import imageCompression from 'browser-image-compression';

const Chispitalaburra = () => {
    const [id, setId] = useState('');
    const [password, setPassword] = useState('');
    const [secKey, setSecKey] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [showAdminPanel, setShowAdminPanel] = useState(false);
    const [qrCodeData, setQrCodeData] = useState('');
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const [settings, setSettings] = useState({
        Ardb_base64_img: "N/Info",
        Ardb_nom_name: "N/Info",
        color1: '#ffffff',
        color2: '#ffffff',
        color3: '#ffffff',
        inputText: '',
        textArea: '',
        regularInput: ''
    });

    const handleLogin = async (e) => {
        e.preventDefault();
        setErrorMessage('');
        setSuccessMessage('');
        setShowAdminPanel(false); 

        try {
            const response = await axios.post('https://lovecompany.xyz/sudodaemon/dw_comp/securitie', {
                id,
                Password: password,
                SecKey: secKey,
            });

            if (response.status === 601) {
                setSuccessMessage('Inicio de sesión exitoso!');
               
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 613) {
                    setShowAdminPanel(true); 
                    setErrorMessage('Usuario encontrado. Acceso a panel de administración.');
                } else {
                    setErrorMessage(error.response.data.message || 'Error en el inicio de sesión');
                }
            } else {
                setErrorMessage('Error al conectarse con el servidor');
            }
        }
    };

    const [recordData, setRecordData] = useState({
        Ardb_nombre: '',
        Ardb_fecha: '',
        Ardb_lugar: '',
        Ardb_desc: '',
        Ardb_cat: '',
        Ardb_collection: '',
        Ardb_stat_disp: '',
        Ardb_num_ins_coll: '',
        Ardb_dig: '',
        Ardb_mine_disp: '',
        Ardb_base64_img: '',
        Ardb_yt_url: '',
        Ardb_base64_qr: ''
    });

    const handleRecordDataChange = (e) => {
        const {name, value} = e.target;
        setRecordData((prevData) => ({...prevData, [name]: value}));
    };

    const handleRecordSubmit = async (e) => {
        e.preventDefault();

        const dataToSend = {
            Ardb_nombre: recordData.Ardb_nombre || "N/Info",
            Ardb_fecha: recordData.Ardb_fecha || "N/Info",
            Ardb_lugar: recordData.Ardb_lugar || "N/Info",
            Ardb_desc: recordData.Ardb_desc || "N/Info",
            Ardb_cat: recordData.Ardb_cat || "N/Info",
            Ardb_collection: recordData.Ardb_collection || "N/Info",
            Ardb_stat_disp: recordData.Ardb_stat_disp || "N/Info",
            Ardb_num_ins_coll: recordData.Ardb_num_ins_coll || "N/Info",
            Ardb_dig: recordData.Ardb_dig || "N/Info",
            Ardb_mine_disp: recordData.Ardb_mine_disp || "N/Info",
            Ardb_base64_img: recordData.Ardb_base64_img || "N/Info",
            Ardb_yt_url: recordData.Ardb_yt_url || "N/Info",
            Ardb_base64_qr: recordData.Ardb_base64_qr || "N/Info",
        };

        console.log('Datos a enviar:', JSON.stringify(dataToSend, null, 2));

        try {
            const response = await axios.post('https://lovecompany.xyz/sudodaemon/gal_reg/', recordData);
            if (response.status === 201) {
                alert('Registro creado exitosamente');
            } else {
                alert('Error al crear el registro');
            }
        } catch (error) {
            console.error('Error al enviar el registro:', error);
            alert('Error al enviar el registro');
        }
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const compressedFile = await imageCompression(file, {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 512,
                });

                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64Img = reader.result; 

                    setRecordData((prevData) => ({
                        ...prevData,
                        Ardb_base64_img: base64Img,
                        Ardb_base64_qr: "N/Info", 
                    }));
                };
                reader.readAsDataURL(compressedFile); 
            } catch (error) {
                console.error("Error comprimiendo la imagen:", error);
            }
        }
    };

    const handleItemSelect = (selectedItem) => {
        console.log('Selected Item:', selectedItem);

        const { Ardb_base64_img, Ardb_nom_name } = selectedItem;

        setSettings((prevSettings) => ({
            ...prevSettings,
            Ardb_base64_img: Ardb_base64_img || "N/Info",
            Ardb_nom_name: Ardb_nom_name || "N/Info",
        }));
    };

    const handleColorChange = (colorKey, colorValue) => {
        setSettings((prevSettings) => ({
            ...prevSettings,
            [colorKey]: colorValue,
        }));
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setSettings((prevSettings) => ({
            ...prevSettings,
            [name]: value, 
        }));
    };

    const handleSave = async () => {
        const payload = {
            banner_Text: settings.regularInput,
            banner_img: settings.Ardb_base64_img, 
            carrousel_Text: settings.textArea,
            main_Color: settings.color1,
            sec_Color: settings.color2,
            thr_Color: settings.color3,
        };

        try {
            const response = await axios.post('https://lovecompany.xyz/sudodaemon/gal_reg/settings', payload);
            console.log('Settings saved successfully:', response.data);
        } catch (error) {
            console.error('Error saving settings:', error);
        }
    };

    const fetchDropdownData = async () => {
        try {
            const response = await axios.post('https://lovecompany.xyz/sudodaemon/gal_reg/filter', {});
            console.log('API Response:', response.data);
            setDropdownOptions(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        fetchDropdownData();
    }, []);


    return (
        <div className="bg-gray-900 min-h-screen flex flex-col justify-center items-center p-5">
            <h1 className="text-3xl font-bold text-white mb-5">¡Bienvenido a Chispitalaburra!</h1>
            <form onSubmit={handleLogin} className="bg-gray-800 p-5 rounded shadow-lg w-full max-w-sm">
                <div className="mb-4">
                    <label htmlFor="id" className="block text-gray-300 text-sm font-bold mb-2">ID</label>
                    <input
                        type="text"
                        id="id"
                        value={id}
                        onChange={(e) => setId(e.target.value)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        required
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="password" className="block text-gray-300 text-sm font-bold mb-2">Contraseña</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        required
                    />
                </div>
                <div className="mb-6">
                    <label htmlFor="secKey" className="block text-gray-300 text-sm font-bold mb-2">SecKey</label>
                    <input
                        type="text"
                        id="secKey"
                        value={secKey}
                        onChange={(e) => setSecKey(e.target.value)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        required
                    />
                </div>
                <div className="flex items-center justify-between">
                    <button
                        type="submit"
                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                        Iniciar Sesión
                    </button>
                </div>
                {errorMessage && <p className="text-red-500 mt-4">{errorMessage}</p>}
                {successMessage && <p className="text-green-500 mt-4">{successMessage}</p>}
            </form>

            {/* Admin Panel */}
            {showAdminPanel && (
                <div className="bg-gray-700 p-5 rounded shadow-lg mt-6 w-full max-w-sm">
                    <h2 className="text-2xl font-bold text-white mb-4">Panel de Administración</h2>
                    <p className="text-white">Bienvenido al panel de administración. Aquí puede gestionar los ajustes
                        avanzados.</p>
                    <form onSubmit={handleRecordSubmit} className="mt-4">
                        <input
                            type="text"
                            name="Ardb_nombre"
                            placeholder="Nombre"
                            onChange={handleRecordDataChange}
                            className="w-full p-2 mb-2 rounded"
                        />
                        <input
                            type="date"
                            name="Ardb_fecha"
                            placeholder="Fecha"
                            onChange={handleRecordDataChange}
                            className="w-full p-2 mb-2 rounded"
                        />
                        <input
                            type="text"
                            name="Ardb_lugar"
                            placeholder="Lugar"
                            onChange={handleRecordDataChange}
                            className="w-full p-2 mb-2 rounded"
                        />
                        <textarea
                            name="Ardb_desc"
                            placeholder="Descripción"
                            onChange={handleRecordDataChange}
                            className="w-full p-2 mb-2 rounded"
                        />

                        <input
                            type="text"
                            name="Ardb_cat"
                            placeholder="Categoría"
                            onChange={handleRecordDataChange}
                            className="w-full p-2 mb-2 rounded"
                        />
                        <input
                            type="text"
                            name="Ardb_collection"
                            placeholder="Colección"
                            onChange={handleRecordDataChange}
                            className="w-full p-2 mb-2 rounded"
                        />
                        <input
                            type="text"
                            name="Ardb_stat_disp"
                            placeholder="Estado de Disponibilidad"
                            onChange={handleRecordDataChange}
                            className="w-full p-2 mb-2 rounded"
                        />
                        <input
                            type="text"
                            name="Ardb_num_ins_coll"
                            placeholder="Número en la Colección"
                            onChange={handleRecordDataChange}
                            className="w-full p-2 mb-2 rounded"
                        />
                        <select
                            name="Ardb_dig"
                            onChange={handleRecordDataChange}
                            className="w-full p-2 mb-2 rounded"
                        >
                            <option value="">Digital?</option>
                            {/* Opción por defecto */}
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                        <select
                            name="Ardb_mine_disp"
                            onChange={handleRecordDataChange}
                            className="w-full p-2 mb-2 rounded"
                        >
                            <option value="">MineDisp?</option>
                            {/* Opción por defecto */}
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                        <input
                            type="file"
                            accept="image/*" 
                            onChange={handleFileChange}
                            className="w-full p-2 mb-2 rounded"
                        />
                        <input
                            type="url"
                            name="Ardb_yt_url"
                            placeholder="URL de YouTube"
                            onChange={handleRecordDataChange}
                            className="w-full p-2 mb-2 rounded"
                        />
                        {qrCodeData && (
                            <img
                                src={qrCodeData}
                                alt="QR Code"
                                className="w-full mb-2 rounded"
                            />
                        )}
                        <input
                            type="text"
                            name="Ardb_base64_qr"
                            value={qrCodeData}
                            readOnly
                            className="w-full p-2 mb-2 rounded"
                        />

                        <button
                            type="submit"
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                            Crear Registro
                        </button>
                    </form>
                    {/* Subcategoría General */}
                    <div>
                        <h3>General</h3>
                        <select className="w-full p-2 mb-2 rounded">
                            <option value="">Seleccione un Nombre</option>
                            {dropdownOptions.map((item) => (
                                <option key={item.Ardb_nombre} value={item.Ardb_nombre}>
                                    {item.Ardb_nombre}
                                </option>
                            ))}
                        </select>

                        <div>
                            <label>
                                Color 1:
                                <input
                                    type="color"
                                    value={settings.color1}
                                    onChange={(e) => handleColorChange('color1', e.target.value)}
                                />
                            </label>
                        </div>
                        <div>
                            <label>
                                Color 2:
                                <input
                                    type="color"
                                    value={settings.color2}
                                    onChange={(e) => handleColorChange('color2', e.target.value)}
                                />
                            </label>
                        </div>
                        <div>
                            <label>
                                Color 3:
                                <input
                                    type="color"
                                    value={settings.color3}
                                    onChange={(e) => handleColorChange('color3', e.target.value)}
                                />
                            </label>
                        </div>

                        {/* Text Area */}
                        <div>
                            <label>
                                Regular Input:
                                <input
                                    type="text"
                                    name="regularInput"
                                    value={settings.regularInput}
                                    onChange={handleInputChange}
                                    className="w-full p-2 mb-2 rounded"
                                    placeholder="Regular input field"
                                />
                            </label>
                        </div>
                        <div>
                            <label>
                                Text Area:
                                <textarea
                                    name="textArea"
                                    value={settings.textArea}
                                    onChange={handleInputChange}
                                    className="w-full p-2 mb-2 rounded"
                                    placeholder="Enter detailed information"
                                />
                            </label>
                        </div>

                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            onClick={handleSave}>Guardar Ajustes
                        </button>
                    </div>

                </div>

            )}
        </div>
    );
};

export default Chispitalaburra;
