import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Chat from './components/chat.js';
import Gallery from './components/Gallery.js';
import Chispitalaburra from './components/Chispitalaburra';
import Home from './components/Home.js';
import UnderDevelopment from './components/UnderDevelopment.js';
import OpiumBrowser from './components/OpiumBrowser';
import HundredTificLottery from './components/100tificLottery.js'
import SecureLog from './components/SecureLog.js';
import Corruptor from './components/Corruptor.js';

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/Chat" element={<Chat />} />
                <Route path="/Gallery" element={<Gallery />} />
                <Route path="/chicle" element={<Chispitalaburra />} />
                <Route path="/h0me" element={<Home />} />
                <Route path="/dev" element={<UnderDevelopment />} />
                <Route path="/Fantomo" element={<OpiumBrowser />} />
                <Route path='/100tificshit' element={<HundredTificLottery />} />
                <Route path='/L0g' element={<SecureLog />} />
                <Route path='/C0rrupt' element={<Corruptor />} />
            </Routes>
        </Router>
    );
};

export default App;
