import React, { useState } from 'react';
import logo from '../imgs/main.ico'; 

const Corruptor = () => {
  const [file, setFile] = useState(null);
  const [corruptedFileUrl, setCorruptedFileUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isInputHidden, setIsInputHidden] = useState(false);

  const handleFileUpload = (e) => {
    const uploadedFile = e.target.files[0];
    setFile(uploadedFile);

    if (uploadedFile) {
      setIsLoading(true); 
      setTimeout(() => {
        const reader = new FileReader();
        reader.onload = () => {
          const corruptedData = new Uint8Array(reader.result);
          for (let i = 0; i < corruptedData.length; i++) {
            corruptedData[i] = corruptedData[i] ^ 0xff;
          }
          const corruptedBlob = new Blob([corruptedData], { type: uploadedFile.type });
          const url = URL.createObjectURL(corruptedBlob);
          setCorruptedFileUrl(url);
          setIsLoading(false); 
          setIsInputHidden(true); 
        };
        reader.readAsArrayBuffer(uploadedFile);
      }, 5000); 
    }
  };

  return (
    <div
      className="flex items-center justify-center h-screen bg-cover bg-center"
      style={{
        backgroundImage: "url('https://i.pinimg.com/736x/8f/1e/48/8f1e48ebffb682f4fab69ffeb1062a2e.jpg')",
      }}
    >
      <img
        src={logo}
        alt="Logo"
        className="absolute top-4 right-4 w-12 h-12"
      />

      {isLoading ? (
        <div className="flex flex-col items-center">
          <div className="w-12 h-12 border-4 border-gray-300 border-t-red-500 rounded-full animate-spin mb-4"></div>
          <p className="text-lg font-semibold text-white">Processing your file...</p>
        </div>
      ) : (
        <div className="bg-white shadow-lg rounded-lg p-6 w-96 text-center">
          <h2 className="text-xl font-semibold mb-4">File Corruptor</h2>
          {!isInputHidden && (
            <input
              type="file"
              onChange={handleFileUpload}
              className="block w-full mb-4 px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-blue-300"
            />
          )}
          {corruptedFileUrl && (
            <a
              href={corruptedFileUrl}
              download={`corrupted-${file.name}`}
              className="block bg-red-500 text-white font-semibold py-2 px-4 rounded-lg hover:bg-red-600 transition"
            >
              Download Corrupted File
            </a>
          )}
        </div>
      )}
    </div>
  );
};

export default Corruptor;


