import React, { useState, useEffect } from "react";
import "./HundredTificLottery.css";

const HundredTificLottery = () => {
  const [textList, setTextList] = useState([
    "Welcome 100tific Lottery.",
  ]);

  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [intervalTime, setIntervalTime] = useState(6000);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [newIntervalTime, setNewIntervalTime] = useState(intervalTime);
  const [newTextList, setNewTextList] = useState([...textList]);
  const [shuffledList, setShuffledList] = useState([...textList]);
  const [isStarted, setIsStarted] = useState(false);

  useEffect(() => {
    document.title = "100-tifickshit";
  }, []);

  useEffect(() => {
    if (isStarted && shuffledList.length > 0) {
      const interval = setInterval(() => {
        setCurrentTextIndex((prevIndex) => {
          if (prevIndex + 1 < shuffledList.length) {
            return prevIndex + 1;
          } else {
            clearInterval(interval);
            return prevIndex + 1;
          }
        });
      }, intervalTime);

      return () => clearInterval(interval);
    }
  }, [shuffledList, intervalTime, isStarted]);

  // Leer el texto usando SpeechSynthesis API
  useEffect(() => {
    if (currentTextIndex < shuffledList.length) {
      const utterance = new SpeechSynthesisUtterance(
        shuffledList[currentTextIndex]
      );
      window.speechSynthesis.speak(utterance);
    }
  }, [currentTextIndex, shuffledList]);

  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const handleSave = () => {
    if (newIntervalTime > 0) {
      setIntervalTime(newIntervalTime);
      setTextList(newTextList);
      setShuffledList(shuffleArray(newTextList));
      setCurrentTextIndex(0);
      setIsPopupOpen(false);
      setIsStarted(true);
    } else {
      alert("El tiempo debe ser mayor a 0.");
    }
  };

  const handleAddText = () => {
    setNewTextList([...newTextList, "Nuevo texto"]);
  };

  const handleDeleteText = (index) => {
    setNewTextList(newTextList.filter((_, i) => i !== index));
  };

  const handleEditText = (index, value) => {
    const updatedList = [...newTextList];
    updatedList[index] = value;
    setNewTextList(updatedList);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-800 to-red-900 text-white hundred-tific-lottery">
      {" "}
      {/* Aplica la clase aquí */}
      <header className="text-center py-6 shadow-lg bg-opacity-90 bg-red-700">
        <h1 className="text-4xl font-extrabold tracking-wider">
          100tific Lottery
        </h1>
        <p className="text-lg mt-2 font-light">
          ¡Bienvenido a la experiencia más emocionante de lotería científica!
        </p>
      </header>
      <main className="container mx-auto px-4 py-10">
        <section className="text-center mb-10">
          <h2 className="text-3xl font-bold">¿Cómo funciona?</h2>
          <p className="mt-4 text-lg leading-relaxed">
            {isStarted
              ? currentTextIndex < shuffledList.length
                ? shuffledList[currentTextIndex]
                : "Done"
              : "Configura..."}
          </p>
          <button
            onClick={() => {
              setNewTextList([...textList]);
              setIsPopupOpen(true);
            }}
            className="mt-6 bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md transition duration-300"
          >
            Configurar
          </button>
        </section>
      </main>
      <footer className="text-center py-6 bg-red-700 mt-10">
        <p className="text-sm">© 2024 100tific Lottery. -LilSun.</p>
      </footer>
      {/* Popup Modal */}
      {isPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg text-gray-800 max-w-md w-full">
            <h3 className="text-xl font-bold mb-4">Configuración</h3>
            <label className="block text-sm mb-2">
              Tiempo en milisegundos:
            </label>
            <input
              type="number"
              value={newIntervalTime}
              onChange={(e) => setNewIntervalTime(Number(e.target.value))}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
              min="1"
            />
            <h4 className="text-lg font-semibold mt-4 mb-2">Lista de Textos</h4>
            <div className="space-y-2">
              {newTextList.map((text, index) => (
                <div key={index} className="flex items-center space-x-2">
                  <input
                    type="text"
                    value={text}
                    onChange={(e) => handleEditText(index, e.target.value)}
                    className="flex-1 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-red-500"
                  />
                  <button
                    onClick={() => handleDeleteText(index)}
                    className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-2 rounded-md"
                  >
                    Eliminar
                  </button>
                </div>
              ))}
            </div>
            <button
              onClick={handleAddText}
              className="mt-4 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-md"
            >
              Agregar Nuevo Texto
            </button>
            <div className="mt-6 flex justify-end space-x-2">
              <button
                onClick={() => setIsPopupOpen(false)}
                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-md"
              >
                Cancelar
              </button>
              <button
                onClick={handleSave}
                className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md"
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HundredTificLottery;
