import React, { useState } from "react";

const SecureLog = () => {
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Accedemos a la variable de entorno REACT_APP_PASSWORD
    const storedPassword = process.env.REACT_APP_PASSWORD;
    console.log("storedPassword", storedPassword);

    if (password == storedPassword) {
      setIsAuthenticated(true);
    } else {
      alert("Incorrect password");
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-500 to-blue-600 flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-lg w-96">
        <h2 className="text-2xl font-bold text-center text-purple-600 mb-6">
          Secure Login
        </h2>

        {!isAuthenticated ? (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Enter Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-3 mt-1 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                required
              />
            </div>

            <div>
              <button
                type="submit"
                className="w-full bg-purple-600 text-white py-2 rounded-md hover:bg-purple-700 transition duration-300"
              >
                Login
              </button>
            </div>
          </form>
        ) : (
          <div className="text-center">
            <h3 className="text-xl font-semibold text-green-600">Welcome!</h3>
            <p className="mt-4 text-lg">You are now authenticated.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SecureLog;
