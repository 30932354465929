// LoadingSpinner.js
import React from 'react';
import { Hearts } from 'react-loader-spinner';
import './styles.css';

const LoadingSpinner = () => {
    return (
        <div className="loading-back">
        <div className="flex flex-col justify-center items-center h-screen">
            <Hearts
                height={300}
                width={300}
                color="#8f0381"
                ariaLabel="loading"
            />
            <div className="mt-4 w-35"> {/* Ajusta w-24 a la medida que necesites */}
                <p className="text-2xl typewriter-text">Loading...</p>
            </div>
        </div>
        </div>
    );
};

export default LoadingSpinner;
